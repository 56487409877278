import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import { NavLink, useNavigate, useLocation} from "react-router-dom";
import Notiflix from 'notiflix';
import { doc } from 'firebase/firestore';

const Help = () => {

    const validationSchema = Yup.object({
        userquery: Yup.string().required('*Please enter your query').required(),
      });
      const formOptions = { resolver: yupResolver(validationSchema) };
      const { register, handleSubmit, formState } = useForm(formOptions);
      const { errors } = formState;
      const [user, loading, error] = useAuthState(auth);
      const location = useLocation();
      const [formLoading, setFormLoading] = useState(null);

      const navigate = useNavigate();

      const onSubmit = async (e) => {
        // handleSendIvite({userName,useremailId});
      }

      useEffect(()=>{
        if(!loading && !user) return navigate("/onboarding");
     },[loading]); 
      
    return (
        <div className='w-[100%] h-full lg:w-[94%] px-3 lg:mx-[20px] bg-gray-color sm:bg-secondary-color relative'>
            <Helmet>
                <title>Brainstormer Help and Support</title>
            </Helmet>
            {user ?
            <div className='help-support-wrapper'>
                <div className="help-support-inner">
                    <div className="breadcrumbs-ct hidden sm:block my-4">
                        <ul className='flex items-center'>
                            <li className='text-[14px] list-none text-graydark-color mr-[7px] font-semibold cursor-pointer'><Link to={'/'}>Home</Link></li><span>/</span>
                            <li className='text-[14px] list-none text-graydark-color mx-[7px] font-semibold cursor-pointer'><Link to={'/account'}>My Profile</Link></li><span>/</span>
                            <li className='text-[14px] list-none text-tertiary-color mx-[7px] font-semibold'>Help & Support</li>
                        </ul>
                    </div>
                    <div className="mob-heading flex justify-start items-center my-[14px] sm:hidden">
                        <Link className="ml-[-10px]" to="/account"><span className='icon-leftarrowIcon text-primary-color text-[24px] block mx-2 mt-1'></span></Link>
                        <h2 className='font-bold text-[20px] text-tertiary-color'>Help & Support</h2>
                    </div>
                    {/* <h2 className='text-tertiary-color text-[20px] font-bold mb-[8px]'>Send us your query</h2> */}
                    {/* <p className='text-graydark-color text-[15px] font-semibold max-w-[340px]'>We will contact you via email  in the next 1 - 2 business days.</p> */}
                    {/* <form action="" className='flex flex-col justify-end'>
                        <textarea name="query" id=""  rows="5" maxLength="5000" className='w-full border border-[#DDDFE5] my-2 px-2' {...register('userquery')}>

                        </textarea>
                        <span className='text-red-500 sm:text-sm'>{errors.userquery?.message}</span>
                        <button onClick={handleSubmit(onSubmit)} className='w-[110px] lg:w-[200px] xl:w-[300px] bg-primary-color rounded-[6px] h-[42px] flex justify-center items-center font-semibold hover:bg-opacity-75 mt-[12px] p-0 text-[18px] self-end'>Send</button>
                    </form> */}
                    <div className='flex justify-between items-center mt-[40px]'>
                        {/* <div className='w-[65%] sm:w-[70%] lg-w-auto'>
                            <h2 className='text-tertiary-color text-[20px] font-bold mb-[8px]'>Send us your query</h2>
                            <p className='text-graydark-color text-[15px] font-semibold'>We will contact you via email  in the next 1 - 2 business days.</p>
                        </div> */}
                        {/* <button onClick={()=>{window.Usersnap.logEvent('feedback_submit')}} id="send-enquiry" className='w-[110px] lg:w-[200px] xl:w-[300px] bg-primary-color rounded-[6px] h-[42px] flex justify-center items-center font-semibold hover:bg-opacity-75 p-0 px-[0px] md:text-[18px]'>Send</button> */}
                    </div>
                    <div id="feedback-container" className='relative'>
                        <iframe id="feedback-form" src='' className='absolute' width="50%" height="500"></iframe>
                    </div>
                    {/* <div className='w-full my-[30px]'>
                        <span className='text-graydark-color text-[18px] text-center w-full block my-4 text-bold'>or</span>
                    </div> */}
                    {/* <div className='flex justify-between items-center'>
                        <div className='w-[65%] sm:w-[70%] lg-w-auto'>
                            <h2 className='text-tertiary-color text-[20px] font-bold mb-[8px]'>Talk to our Support Bot</h2>
                            <p className='text-graydark-color text-[15px] font-semibold'>Talk to our Brainstormer support bot 24 x 7</p>
                        </div>
                        <button className='w-[110px] lg:w-[200px] xl:w-[300px] bg-primary-color rounded-[6px] h-[42px] flex justify-center items-center font-semibold hover:bg-opacity-75 p-0 px-[0px]'>Chat Now</button>
                    </div> */}
                </div>
            </div>:!loading? <p className='m-[15px] sm:my-[25px] sm:ml-[20px] lg:ml-[0px] pl-0 relative left-[-8px] text-graydark-color font-semibold'>You are not logged In! Please <Link to="/login" className='text-primary-color font-semibold underline'>Login</Link> and try again</p>:null}
            {
                formLoading != false ? 
                 <div className="loader-ct h-[100%] absolute top-[0px] left-0">
                    <img src='/assets/images/loader3.gif' width={30} height={30} alt='loader' loading="lazy" className='relative bottom-[20px]'></img>   
                </div>:null
            }  
        </div>
    )
}

export default Help