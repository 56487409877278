/*common login user data store*/

import {collection,addDoc,setDoc,doc,Timestamp} from "firebase/firestore";
export const StoreUserData = async (user,db,loginMethod) =>{
    var myTimestamp = Timestamp.fromDate(new Date());
    var myDate = myTimestamp.toDate();

    // Add one month to the date
    myDate.setMonth(myDate.getMonth() + 1);

    // Convert the updated JavaScript Date back to a Firestore Timestamp
    var newTimestamp = Timestamp.fromDate(myDate);
    return setDoc(doc(db, "Users",user.uid),{
   // return addDoc(collection(db, "User").doc(user.uid), {
        uid: user.uid,
        name: user.displayName,
        authProvider: loginMethod,
        email: user.email,
        subscription_plan: "Free",
        subscription_end_date:newTimestamp
    });
}