import React from 'react';
import Channelcard from './Channelcard';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useOutletContext } from "react-router-dom"
import { useParams } from "react-router-dom"
import { useAuthState } from "react-firebase-hooks/auth";
import { SENDBIRD_INFO } from '../constants/constants';
import { auth} from "../firebase";
import ProfileIcon from './ProfileIcon';
import {Helmet} from "react-helmet-async"
import Breadcrumbs from './Breadcrumbs';
import Notiflix from 'notiflix';
import { useNavigate, BrowserRouter as Router, Link, } from "react-router-dom";
import { useQuery } from '@tanstack/react-query';


const Botlist = () => {
  const navigate = useNavigate();
  let params = useParams()
  const [user, loading, error] = useAuthState(auth);
  const [createChannel,userNameInputValue] = useOutletContext();
  const slug=params.slug

    const HandleBackBtnNavigate =() => {
      navigate('/library');
    }    

  const getCategoryAllBot = async () => {
    try {
      // const usertoken = await user.getIdToken();
      const headers = {
        'Api-token': SENDBIRD_INFO.apiToken,
      };

      if (user) {
        const usertoken = await user.getIdToken();
        headers.Authorization = `Bearer ${usertoken}`;
      }
      const response = await axios.get(`${SENDBIRD_INFO.newBotUrl}/get_bots/${slug}`, {
        headers
      });

      const botData = response.data.data[0].attributes;

      return botData;
    } catch (error) {
      console.log(error.message + 'file-error');
      throw error;
    }
  }

  const { data: botData, isLoading } = useQuery({
    queryKey: ['bots', slug, user], //keys added in after first key(key for fn name) acts as the dependencies, on change of them the data is refetched.
    queryFn: getCategoryAllBot,
    // enabled: !!user,
  });

  useEffect(()=>{
    if(!loading && !user) return navigate("/onboarding");
  },[loading]);

  if (isLoading) {
    return (
      <div className="loader-ct">
        <img src='/assets/images/loader3.gif' width={30} height={30} alt='loader'></img>
      </div>
    )
  }
  
  return (
    <>

      <div className='sm:mx-3 bot-lib listing w-full pb-[80px] sm:pb-[0px]'>
        <Helmet>
          <title>{`Brainstormer - ${botData?.Name}`}</title>
          <meta name="description" content={`${botData?.Description}`} />
          <meta property="og:title" content={`Brainstormer - ${botData?.Name}`}></meta>
          <meta property="og:description" content={`${botData?.Description}`} />
        </Helmet>

        <div className='hidden sm:block  pt-5 sm:mx-2 '>
          <Breadcrumbs botcategory={botData.Name}/>
        </div>

        <div className="mb-logo-section">
          <div className='flex items-center'>
            <img onClick={HandleBackBtnNavigate} className="" src="/assets/images/left-arrow-white.svg" alt="brainstromapp" />
            <h1 className='mb-heading sm:text-xl text-tertiary-color font-bold px-5 my-3 mx-4'>{botData.Name}</h1>
          </div>
          
          {userNameInputValue ? <ProfileIcon userid={userNameInputValue.uid} username={userNameInputValue.displayName} /> : null}
        </div>
        <div className='flex items-center  pt-4 pb-1 '>
          <Link to='/library' className=''><span className='icon-leftarrowIcon text-primary-color text-[20px] block ml-2 mt-1' onClick={HandleBackBtnNavigate}></span></Link>
          <h2 className='text-2xl font-bold px-2 text-primary-color flex items-center'>
            {botData.Name}
          </h2>
        </div>

        <div className="wrapper sm:mx-2 mt-2 sm:mt-0 pt-2 sm:pt-0">
          <p className='text-left text-base sm:px-1 px-[20px] pt-0 sm:pt-3 pb-2 text-gray-400 '>{botData.Description}</p>
          <div className="bot-content-ct flex flex-wrap w-full secondary-color rounded-lg justify-start mt-2">
            {botData?.bots?.data.map((item, i) =>
              <Channelcard data={item} key={i} createChannel={createChannel} imagePath={SENDBIRD_INFO.studioUrl} />
            )}
          </div>
        </div>
      </div>
    </>
  )


}

export default Botlist