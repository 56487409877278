// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    OAuthProvider,
    deleteUser,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
  } from "firebase/auth";
  import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
  } from "firebase/firestore";
  import { NavLink, useNavigate } from 'react-router-dom'
import Notiflix from "notiflix";
import { SENDBIRD_INFO } from "./constants/constants";
import { analytic } from "./utils/Analytics";
import { StoreUserData } from "./utils/StoreUserData";
import axios from 'axios';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyDZyvij1awV4oA2u-xndJVI0rzJEJqwS88",
//   authDomain: "brainstormerbot.firebaseapp.com",
//   projectId: "brainstormerbot",
//   storageBucket: "brainstormerbot.appspot.com",
//   messagingSenderId: "92699587769",
//   appId: "1:92699587769:web:093519d1f06d9535115768"
// };
const firebaseConfig = {
  apiKey: SENDBIRD_INFO.firebase.apiKey,
  authDomain: SENDBIRD_INFO.firebase.authDomain,
  projectId: SENDBIRD_INFO.firebase.projectId,
  storageBucket: SENDBIRD_INFO.firebase.storageBucket,
  messagingSenderId:SENDBIRD_INFO.firebase.messagingSenderId,
  appId:SENDBIRD_INFO.firebase.appId,
  measurementId: SENDBIRD_INFO.firebase.measurementId
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

export const auth = getAuth(app);
export default app;

const googleProvider = new GoogleAuthProvider();
export const signInWithGoogle = async () => {
    try {
      Notiflix.Loading.standard();
      const res = await signInWithPopup(auth, googleProvider);
      const user = res.user;
      const q = query(collection(db, "Whitelist"), where("email", "==", user.email));
      const docs = await getDocs(q);
      const address = user.email.split('@').pop()
      const data ={
        "uid":user.uid,
      }
      analytic("login",data,"signInWithGoogle");

      if (docs.docs.length === 0 && !SENDBIRD_INFO.allowedDomains.includes(address)) {
        await addDoc(collection(db, "Waitlist"), {
            email: user.email,
          });
        const userauth = auth.currentUser;         
        deleteUser(userauth).then(() => {
          // User deleted.
          window.location = "/login?waitlist=true"
          return true;
        }).catch((error) => {
            console.log(error);
        });
      }else{
          //Store User data in database
          const qUser = query(collection(db, "Users"), where("uid", "==", user.uid));
          const qDocs = await getDocs(qUser);

          if (qDocs.docs.length === 0) {
            await StoreUserData(user,db,'google')
          }
          try {
            const usertoken = await user?.getIdToken();
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${SENDBIRD_INFO.newBotUrl}/admin/users/platform`,
                headers: {
                    Authorization: `Bearer ${usertoken}`,
                    'Api-token': SENDBIRD_INFO.apiToken,
                },
            };
            axios.request(config)
                .then((response) => {
                    window.location = '/';
                })
                .catch((error) => {
                    console.log(error.response.status);
                    if(error.response.status == 401){
                        signOut(auth).then(() => {
                            window.location = "/login?block=true"
                            }).catch((error) => {
                        });
                    }
                });
            } catch (error) {
                console.log(error.message);
                throw error;
            }
        return true;
      }
  } catch (err) {
    console.error(err);
    //alert(err.message);
  }
};
export const signInWithMicrosoft = async () => {
  try {
    const provider = new OAuthProvider('microsoft.com');
    const res = await signInWithPopup(auth, provider);
    const user = res.user;
    const q = query(collection(db, "Whitelist"), where("email", "==", user.email));
    const docs = await getDocs(q);
    const address = user?.email?.split('@').pop()
    const data ={
      "uid":user.uid,
    }
    analytic("login",data,"signInWithMicrosoft");
    if (docs.docs.length === 0 && !SENDBIRD_INFO.allowedDomains.includes(address)) {
      await addDoc(collection(db, "Waitlist"), {
          email: user.email,
        });
      const userauth = auth.currentUser;         
      deleteUser(userauth).then(() => {
        // User deleted.
        window.location = "/login?waitlist=true"
        return true;
      }).catch((error) => {
          console.log(error);
      });
    }else{
        const qUser = query(collection(db, "Users"), where("uid", "==", user.uid));
        const qDocs = await getDocs(qUser);

        if (qDocs.docs.length === 0) {
          await StoreUserData(user,db,'microsoft')
        }
        try {
          const usertoken = await user?.getIdToken();
          let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url: `${SENDBIRD_INFO.newBotUrl}/admin/users/platform`,
              headers: {
                  Authorization: `Bearer ${usertoken}`,
                  'Api-token': SENDBIRD_INFO.apiToken,
              },
          };
          axios.request(config)
              .then((response) => {
                  window.location = '/';
              })
              .catch((error) => {
                  console.log(error.response.status);
                  if(error.response.status == 401){
                      signOut(auth).then(() => {
                          window.location = "/login?block=true"
                          }).catch((error) => {
                      });
                  }
              });
          } catch (error) {
              console.log(error.message);
              throw error;
          }
      return true;
    }
} catch (err) {
  console.error(err);
  //alert(err.message);
}
};

export const signUpWithGoogle = async () => {
  try {
    Notiflix.Loading.standard();
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "Whitelist"), where("email", "==", user.email));
    const docs = await getDocs(q);
    const address = user.email.split('@').pop()
    const data ={
      "uid":user.uid,
    }
    analytic("signup",data,"signUpWithGoogle");
    if (docs.docs.length === 0 && !SENDBIRD_INFO.allowedDomains.includes(address)) {
      await addDoc(collection(db, "Waitlist"), {
          email: user.email,
        });
      const userauth = auth.currentUser;         
      deleteUser(userauth).then(() => {
        // User deleted.
        window.location = "/login?waitlist=true"
        return true;
      }).catch((error) => {
          console.log(error);
      });
    }else{
      //Store User data in database
      const qUser = query(collection(db, "Users"), where("uid", "==", user.uid));
      const qDocs = await getDocs(qUser);

      if (qDocs.docs.length === 0) {
        await StoreUserData(user,db,'google')
      }

      window.location = '/';
      return true;
    }
} catch (err) {
  console.error(err);
  //alert(err.message);
}
};
export const signUpWithMicrosoft = async () => {
try {
  const provider = new OAuthProvider('microsoft.com');
  const res = await signInWithPopup(auth, provider);
  const user = res.user;
  const q = query(collection(db, "Whitelist"), where("email", "==", user.email));
  const docs = await getDocs(q);
  const address = user.email.split('@').pop()
  const data ={
    "uid":user.uid,
  }
  analytic("signup",data,"signUpWithMicrosoft");
  if (docs.docs.length === 0 && !SENDBIRD_INFO.allowedDomains.includes(address)) {
    await addDoc(collection(db, "Waitlist"), {
        email: user.email,
      });
    const userauth = auth.currentUser;         
    deleteUser(userauth).then(() => {
      // User deleted.
      window.location = "/login?waitlist=true"
      return true;
    }).catch((error) => {
        console.log(error);
    });
  }else{
    const qUser = query(collection(db, "Users"), where("uid", "==", user.uid));
    const qDocs = await getDocs(qUser);

    if (qDocs.docs.length === 0) {
      await StoreUserData(user,db,'microsoft')
    }
    window.location = '/';
    return true;
  }
} catch (err) {
console.error(err);
//alert(err.message);
}
};
