import React, { createContext, useState, useEffect, useContext, useRef } from 'react';

const THEME_LIGHT = 'light';
const THEME_DARK = 'dark';
const THEME_DEVICE = 'device';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(localStorage.getItem('data-theme') || THEME_DEVICE);
    const [selectedTheme, setSelectedTheme] = useState(localStorage.getItem('data-selected-theme') || localStorage.getItem('data-theme') || THEME_DEVICE);

    const selectedThemeRef = useRef(selectedTheme);

    useEffect(() => {

        const handleColorSchemeChange = () => {
            const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)').matches;

            if (selectedThemeRef.current === THEME_DEVICE) {
                setTheme(prefersDarkScheme ? THEME_DARK : THEME_LIGHT);
            }
        };

        if (selectedThemeRef.current === THEME_DEVICE) {
            window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', handleColorSchemeChange);
        } else {
            window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', handleColorSchemeChange);
        }

        selectedThemeRef.current = selectedTheme;

        return () => {
            window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', handleColorSchemeChange);
        };

    }, [selectedTheme, theme])

    useEffect(() => {

        if (theme === THEME_DEVICE) {
            const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
            applyTheme(prefersDarkScheme ? THEME_DARK : THEME_LIGHT);
        } else {
            applyTheme(theme);
        }

    }, [theme]);

    const applyTheme = (theme) => {
        document.documentElement.setAttribute('data-theme', theme);
        document.querySelector('html').setAttribute('data-theme', theme);
        localStorage.setItem('data-theme', theme);
    };

    const toggleTheme = (newTheme) => {
        localStorage.setItem('theme-set-user', 'true');
        localStorage.setItem('data-selected-theme', newTheme);
        setTheme(newTheme);
        setSelectedTheme(newTheme);
    };

    return (
        <ThemeContext.Provider value={{ theme, selectedTheme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);