import React, { useRef, useEffect, useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

const CodeHighlighter = ({ language, code}) => {

    const [copied, setCopied] = useState(false);
    const hiddenTextareaRef = useRef(null);
  
    const copyCodeToClipboard = () => {
      if (hiddenTextareaRef.current) {
        hiddenTextareaRef.current.value = codeLines;
        hiddenTextareaRef.current.select();
        document.execCommand('copy');
        setCopied(true);
        setTimeout(() => setCopied(false), 1500); 
      }
    };

    var codeLines = code.split('\n').slice(1).join('\n')

    return (
        <div className='code-ct my-[15px] rounded-[8px] overflow-hidden'>
            <div className='code-heading bg-[#343541] px-4 py-2 text-[#d9d9e3] flex justify-between'>
                <span className='code-name text-white'>{language}</span>
                <button className="copy-button text-[14px] !text-white" onClick={copyCodeToClipboard}>
                    {copied ? 'Copied!' : 'Copy Code'}
                </button>
            </div>
            <SyntaxHighlighter language={language}>
               {codeLines}
            </SyntaxHighlighter>
            <textarea
                ref={hiddenTextareaRef}
                style={{ position: 'absolute', left: '-9999px' }}
                readOnly
            />
        </div>
    );
  };

  export default CodeHighlighter