import { getMessaging, getToken, deleteToken } from 'firebase/messaging';
import SendBird from 'sendbird';
import { SENDBIRD_INFO } from '../constants/constants';

const useNotification = () => {

    const registerNotification = async (user) => {
        try {
            const messaging = getMessaging();
            const appId = SENDBIRD_INFO.appId;
            const vapidKey = SENDBIRD_INFO.vapid_id;
            const sbb = new SendBird({ appId });
    
            await new Promise((resolve, reject) => {
                sbb.connect(user.uid, user.displayName, async (user, error) => {
                    // console.log("user", user);
                    if (error) {
                        reject(error);
                        return;
                    }
                    try {
                        let token = await getToken(messaging, { vapidKey: vapidKey });
                        // console.log("register token", token);
                        if (token) {
                            sbb.registerGCMPushTokenForCurrentUser(token, (response, error) => {
                                // console.log("response", response);
                                if (error) {
                                    reject(error);
                                } else {
                                    // console.log("Token Registered =>", token);
                                    resolve();
                                }
                            });
                        } else {
                            reject(new Error("No token received"));
                        }
                    } catch (error) {
                        reject(error);
                    }
                });
            });
        } catch (error) {
            console.error("Error in registering notification", error);
            throw error;
        }
    };
    
    const unregisterNotification = async (user) => {
        try {
            const messaging = getMessaging();
            const appId = SENDBIRD_INFO.appId;
            const vapidKey = SENDBIRD_INFO.vapid_id;
            const sbb = new SendBird({ appId });
    
            await new Promise((resolve, reject) => {
                sbb.connect(user.uid, user.displayName, async (user, error) => {
                    if (error) {
                        reject(error);
                        return;
                    }
                    try {
                        let token = await getToken(messaging, { vapidKey: vapidKey });
                        // console.log("unregister token", token);
                        if (token) {
                            sbb.unregisterGCMPushTokenForCurrentUser(token, (response, error) => {
                                if (error) {
                                    // reject(error);
                                } else {
                                    // console.log("Token UnRegistered =>", token);
                                    resolve();
                                    deleteToken(messaging);
                                }
                            });
                        } else {
                            // reject(new Error("No token received"));
                        }
                    } catch (error) {
                        // reject(error);
                    }
                });
            });
        } catch (error) {
            console.error("Error in unregistering notification", error);
            // throw error; // Re-throw the error to be caught in handleToggleAllNotifications
        }
    };

    return { registerNotification, unregisterNotification };

}

export default useNotification;