import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
    updateDoc,
    doc
} from "firebase/firestore";
import Notiflix from 'notiflix';
import { useState, useEffect, useRef } from 'react';
import { getMessaging, getToken, deleteToken } from 'firebase/messaging';
import SendBird from 'sendbird';
import { SENDBIRD_INFO } from '../constants/constants';
import { useLocation } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import useNotification from '../hooks/useNotification';

const NotificationSettings = () => {

    const [user, loading, error] = useAuthState(auth);
    const [userData, setUserdata] = useState();
    const [userOpen, setUserOpen] = useState(false);
    const [navOpen, setNavOpen] = useState(false);
    const [notificationLoading, setNotificationLoading] = useState(false);
    const {registerNotification, unregisterNotification} = useNotification();
    // const [createChannel,userNameInputValue] = useOutletContext();

    const navigate = useNavigate();

    if (loading == true) {
        Notiflix.Loading.standard();
    }
    else {
        Notiflix.Loading.remove();
    }

    const fetchUserdata = async () => {
        const data = query(collection(db, "Users"), where('uid', '==', user.uid));
        getDocs(data).then((querySnapshot) => {
            const doc = querySnapshot.docs[0];
            setUserdata(doc?.data() || {});
        })
    }

    useEffect(() => {
        if (!loading && !user) return navigate("/onboarding");
    }, [loading]);

    useEffect(() => {
        if (user) {
            fetchUserdata();
        }
    }, [user]);

    // if ('serviceWorker' in navigator) {
    //     navigator.serviceWorker.register('/firebase-messaging-sw.js')
    //         .then(function (registration) {
    //             console.log('Service Worker registered with scope:', registration.scope, registration);
    //         })
    //         .catch(function (error) {
    //             console.error('Service Worker registration failed:', error);
    //         });
    // }

    // const registerNotification = async () => {
    //     try {
    //         const messaging = getMessaging();
    //         const appId = SENDBIRD_INFO.appId;
    //         const vapidKey = SENDBIRD_INFO.vapid_id;
    //         const sbb = new SendBird({ appId });

    //         await new Promise((resolve, reject) => {
    //             sbb.connect(user.uid, user.displayName, async (user, error) => {
    //                 console.log("user", user);
    //                 if (error) {
    //                     reject(error);
    //                     return;
    //                 }
    //                 try {
    //                     let token = await getToken(messaging, { vapidKey: vapidKey });
    //                     if (token) {
    //                         sbb.registerGCMPushTokenForCurrentUser(token, (response, error) => {
    //                             console.log("response", response);
    //                             if (error) {
    //                                 reject(error);
    //                             } else {
    //                                 console.log("Token Registered =>", token);
    //                                 resolve();
    //                             }
    //                         });
    //                     } else {
    //                         reject(new Error("No token received"));
    //                     }
    //                 } catch (error) {
    //                     reject(error);
    //                 }
    //             });
    //         });
    //     } catch (error) {
    //         console.error("Error in registering notification", error);
    //         throw error;
    //     }
    // };

    // const unregisterNotification = async () => {
    //     try {
    //         const messaging = getMessaging();
    //         const appId = SENDBIRD_INFO.appId;
    //         const vapidKey = SENDBIRD_INFO.vapid_id;
    //         const sbb = new SendBird({ appId });

    //         await new Promise((resolve, reject) => {
    //             sbb.connect(user.uid, user.displayName, async (user, error) => {
    //                 if (error) {
    //                     reject(error);
    //                     return;
    //                 }
    //                 try {
    //                     let token = await getToken(messaging, { vapidKey: vapidKey });
    //                     if (token) {
    //                         sbb.unregisterGCMPushTokenForCurrentUser(token, (response, error) => {
    //                             if (error) {
    //                                 reject(error);
    //                             } else {
    //                                 console.log("Token UnRegistered =>", token);
    //                                 resolve();
    //                             }
    //                         });
    //                     } else {
    //                         reject(new Error("No token received"));
    //                     }
    //                 } catch (error) {
    //                     reject(error);
    //                 }
    //             });
    //         });
    //     } catch (error) {
    //         console.error("Error in unregistering notification", error);
    //         throw error; // Re-throw the error to be caught in handleToggleAllNotifications
    //     }
    // };

    // const changeAllowNotification = async (e) => {

    //     const checkAllCheckbox = document.querySelectorAll('.all-checkbox');
    //     const checkboxes = document.querySelectorAll('.sub-checkbox');
    //     var inviteStormNotify = document.getElementById('invite_storm_notify');
    //     var messageSentNotify = document.getElementById('message_sent_notify');
    //     var checkAllNotification = e.currentTarget.checked;
    //     if (checkAllNotification == true) {
    //         if (inviteStormNotify.checked == false) {
    //             setTimeout(() => {
    //                 inviteStormNotify.click();
    //             }, [250]);
    //         }
    //         if (messageSentNotify.checked == false) {
    //             setTimeout(() => {
    //                 messageSentNotify.click();
    //             }, [500]);
    //         }
    //     }
    //     else {
    //         if (inviteStormNotify.checked == true) {
    //             setTimeout(() => {
    //                 inviteStormNotify.click();
    //             }, [250]);
    //         }
    //         if (messageSentNotify.checked == true) {
    //             setTimeout(() => {
    //                 messageSentNotify.click();
    //             }, [500]);
    //         }
    //     }
    //     const updatedata = {
    //         allnotifications: checkAllNotification,
    //     }
    //     await updateDoc(doc(collection(db, "Users"), user.uid), updatedata).then(() => {
    //         fetchUserdata();
    //         // if(checkAllNotification == true){
    //         //     checkboxes.forEach(function(checkbox) {
    //         //         if(!checkbox.checked){
    //         //             setTimeout(()=>{
    //         //                 checkbox.click();
    //         //             },[100]);
    //         //         }
    //         //     });
    //         // }
    //         // else 
    //         // {
    //         //     checkboxes.forEach(function(checkbox) {
    //         //         if(checkbox.checked){
    //         //             setTimeout(()=>{
    //         //                 checkbox.click();
    //         //             },[100]);
    //         //         }
    //         //     });
    //         // }

    //     });
    // }

    const handleToggleAllNotifications = async (e) => {

        setNotificationLoading(true);
        let checked = e?.currentTarget?.checked;

        try {
            if (checked) {
                await registerNotification(user);
            } else {
                if(Notification.permission !== 'denied')
                    await unregisterNotification(user);
            }

            const updatedata = {
                allnotifications: checked,
                invitenotification: checked,
                messageinvitation: checked,
            }

            await updateDoc(doc(collection(db, "Users"), user.uid), updatedata);

            let updatedData = userData;

            updatedData['allnotifications'] = checked;
            updatedData['invitenotification'] = checked;
            updatedData['messageinvitation'] = checked;

            setUserdata(updatedData);

            if(Notification.permission !== 'denied') {
                if(checked) {
                    updateSendbirdPushTrigger('all');
                } else {
                    updateSendbirdPushTrigger('off');
                }
            }


            // if(userData?.invitenotification != checked)
            //     await handleToggleIndividualSetting('invitenotification', checked);
            // if(userData?.messageinvitation != checked)
            //     await handleToggleIndividualSetting('messageinvitation', checked);

            // fetchUserdata();            
        } catch (error) {
            console.log("err", error);
            console.error("Error toggling all notifications:", error);
            alert("Please enable browser notification permissions to continue");
        } finally {
            setNotificationLoading(false);
        }
    };

    const handleToggleIndividualSetting = async (settingName, e) => {

        setNotificationLoading(true);

        let checked = e?.currentTarget?.checked || e;
        try {
            
            await registerNotification(user);

            await updateSendbirdPushTrigger(getPushTriggerSetting(settingName, checked));

            const updatedata = {
                [settingName]: checked,
            }
    
            let updatedData = { ...userData, [settingName]: checked };
    
            if (checked) {
                if (!updatedData.allnotifications) {
                    updatedata['allnotifications'] = true;
                    updatedData['allnotifications'] = true;
                }
            } else {
                const otherSetting = settingName === 'messageinvitation' ? 'invitenotification' : 'messageinvitation';
                if (!updatedData[otherSetting]) {
                    updatedata['allnotifications'] = false;
                    updatedData['allnotifications'] = false;

                    await unregisterNotification(user);
                }
            }
    
            await updateDoc(doc(collection(db, "Users"), user.uid), updatedata);
    
            setUserdata(updatedData);
        } catch (error) {
            console.error(`Error toggling ${settingName}:`, error);
            alert("Please enable notifications to continue");
        } finally {
            setNotificationLoading(false);
        }
    };

    const updateSendbirdPushTrigger = async (triggerOption) => {
        try {
            const sb = SendBird.getInstance();
            const res = await sb.setPushTriggerOption(triggerOption);
            console.log("triger option", triggerOption);
            console.log("Sendbird push trigger updated:", res);
        } catch (error) {
            console.error("Error updating Sendbird push trigger:", error);
            throw error;
        }
    };

    const getPushTriggerSetting = (settingName, checked) => {
        if (settingName === 'invitenotification') {
            return checked ? userData?.messageinvitation ? 'all' : 'mention_only' : 'off';
        } else if (settingName === 'messageinvitation') {
            return checked ? 'all' : 'off';
        }
        return 'off';
    };

    // const changeInvitedNotification = async (e) => {
    //     var checkInvitedNotification = e.currentTarget.checked;
    //     const updatedata = {
    //         invitenotification: checkInvitedNotification,
    //     }
    //     await updateDoc(doc(collection(db, "Users"), user.uid), updatedata).then(() => {
    //         fetchUserdata();
    //     });
    // }

    // const changeAcceptNotification = async (e) =>{
    //     var checkAcceptNotification = e.currentTarget.checked;
    //     const updatedata = {
    //         acceptnotification:checkAcceptNotification,
    //     }
    //     await updateDoc(doc(collection(db, "Users"), user.uid), updatedata).then(()=>{
    //         fetchUserdata();
    //     });
    // }

    // const changeMessageNotification = async (e) => {
    //     var checkMessageInvitation = e.currentTarget.checked;
    //     const updatedata = {
    //         messageinvitation: checkMessageInvitation,
    //     }
    //     await updateDoc(doc(collection(db, "Users"), user.uid), updatedata).then(() => {
    //         fetchUserdata();
    //         if (checkMessageInvitation == true) {
    //             // onNotification();
    //         }
    //         if (checkMessageInvitation == false) {
    //             // offNotification();
    //         }
    //     });
    // }

    return (
        <div className="w-[100%] h-full lg:w-[94%] px-3 lg:mx-[20px] bg-gray-color sm:bg-secondary-color">
            <Helmet>
                <title>Brainstormer Search Bots</title>
            </Helmet>
            {user ?
                <div className='notification-settings-wrapper'>
                    <div className="notification-settings-inner">
                        <div className="breadcrumbs-ct hidden sm:block my-4">
                            <ul className='flex items-center'>
                                <li className='text-[14px] list-none text-graydark-color mr-[7px] font-semibold cursor-pointer'><Link to={'/'}>Home</Link></li><span>/</span>
                                <li className='text-[14px] list-none text-graydark-color mx-[7px] font-semibold cursor-pointer'><Link to={'/account'}>My Profile</Link></li><span>/</span>
                                <li className='text-[14px] list-none text-tertiary-color mx-[7px] font-semibold'>Notifications Settings</li>
                            </ul>
                        </div>
                        <div className="mob-heading flex justify-start items-center my-[14px] sm:hidden">
                            <Link className="ml-[-10px]" to="/account"><span className='icon-leftarrowIcon text-primary-color text-[24px] block mx-2 mt-1'></span></Link>
                            <h2 className='font-bold text-[20px] text-tertiary-color'>Notifications Settings</h2>
                        </div>
                        <div className='notification-ct flex justify-between items-center border-b border-b-[#E5E5E5] pt-4 pb-8'>
                            <h3 className='text-[20px] font-bold text-tertiary-color'>Allow Push Notifications</h3>
                            <label className="relative inline-flex items-center cursor-pointer">
                                <input type="checkbox" value="" disabled={notificationLoading} checked={userData?.allnotifications == true} onChange={(e) => handleToggleAllNotifications(e)} className="sr-only peer all-checkbox" />
                                <div className={`notification-radio-icon w-14 h-7 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[5px] after:secondary-color after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-primary-color ${notificationLoading ? 'opacity-50 cursor-not-allowed' : ''}`}></div>
                            </label>
                        </div>
                        <div className='notification-ct flex justify-between items-center pt-6 pb-6'>
                            <h4 className='text-[16px] font-bold text-tertiary-color'>Notify when mentioned in a storm</h4>
                            <label className="relative inline-flex items-center cursor-pointer">
                                <input type="checkbox" id="invite_storm_notify" disabled={notificationLoading} value="" checked={userData?.invitenotification == true} onChange={(e) => handleToggleIndividualSetting('invitenotification', !userData?.invitenotification)} className="sr-only peer sub-checkbox" />
                                <div className={`notification-radio-icon w-14 h-7 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[5px] after:secondary-color after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-primary-color ${notificationLoading ? 'opacity-50 cursor-not-allowed' : ''}`}></div>
                            </label>
                        </div>
                        {/* <div className='notification-ct flex justify-between items-center pt-6 pb-6'>
                    <h4 className='text-[16px] font-bold text-tertiary-color'>Notify when user accepts invitation to storm</h4>
                    <label className="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" value="" checked={userData?.acceptnotification == true} onChange={changeAcceptNotification} className="sr-only peer" />
                        <div className="w-14 h-7 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[5px] after:secondary-color after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-primary-color"></div>
                    </label>
                </div> */}
                        <div className='notification-ct flex justify-between items-center pt-6 pb-6'>
                            <h4 className='text-[16px] font-bold text-tertiary-color'>Notify when a message is sent in a storm</h4>
                            <label className="relative inline-flex items-center cursor-pointer">
                                <input type="checkbox" id="message_sent_notify" disabled={notificationLoading} value="" checked={userData?.messageinvitation == true} onChange={(e) => handleToggleIndividualSetting('messageinvitation', !userData?.messageinvitation)} className="sr-only peer sub-checkbox" />
                                <div className={`notification-radio-icon w-14 h-7 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[5px] after:secondary-color after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-primary-color ${notificationLoading ? 'opacity-50 cursor-not-allowed' : ''}`}></div>
                            </label>
                        </div>
                    </div>
                </div> : !loading ? <p className='m-[15px] sm:my-[25px] sm:ml-[20px] lg:ml-[0px] pl-0 relative left-[-8px] text-graydark-color font-semibold'>You are not logged In! Please <Link to="/login" className='text-primary-color font-semibold underline'>Login</Link> and try again</p> : null}

        </div>
    )
}

export default NotificationSettings