import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import CategoryCard from './CategoryCard';
import ProfileIcon from './ProfileIcon';
import { SENDBIRD_INFO } from '../constants/constants';
import { NavLink} from "react-router-dom";
import {Helmet} from "react-helmet";
import Notiflix from 'notiflix';
import Mobfooter from './Mobfooter';
import { useQuery } from '@tanstack/react-query';
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth} from "../firebase";
import { useNavigate } from 'react-router-dom';


const Categorylist = ({ handleLoadMemberSelectionList,userNameInputValue, createChannel, HandleHomeBtn, HandleLibBtn, handleSearchBtn }) => {

  const [user, loading, error] = useAuthState(auth);

  const getAllBot = async () => {
    // Notiflix.Loading.standard();
    try {
      const response = await axios.get(`${SENDBIRD_INFO.newBotUrl}/get_bot_categories`, {
        headers: {
          'Api-token': SENDBIRD_INFO.apiToken
        }
      });

      const data = response.data.data;
      // Notiflix.Loading.remove();
      return data;
    } catch (error) {
      console.log(error.message + 'file-error');
      throw error;
    }
  }

  const { data, isLoading } = useQuery({
    queryKey: ['categories'],
    queryFn: getAllBot
  })

  const closeChannel = () => {
    document.querySelector('.channel').style.zIndex = '0';
  }

  const navigate = useNavigate();

  useEffect(()=>{
    if(!loading && !user) return navigate("/onboarding");
},[loading]);

  return (
    <div className='lg:mx-5 bot-lib relative min-h-[100%]'>
      <Helmet>
        <title>Brainstormer Explore Bots</title>
      </Helmet>
      {/* <div className="mb-logo-section">
        <img onClick={closeChannel} className="back-arrow" src="/assets/images/category-back.svg" alt="brainstromapp" />
        <img className="w-auto brainstrom-logo" src="/assets/images/category-logo.svg" alt="brainstromapp" />
        {userNameInputValue ? <ProfileIcon userid={userNameInputValue.uid} username={userNameInputValue.displayName} /> : null}
      </div> */}
       {isLoading ? <div className="loader-ct absolute w-full h-full top-0 left-0 z-10">
                <img src='/assets/images/loader3.gif' width={30} height={30} alt='loader'></img>
        </div>:null }

      <div className="wrapper mx-4 sm:mx-0 mt-2 pt-2">

      {/* <div className='hidden sm:block '>
        <div className="breadrumbs pt-1">
              <Link to="/">Home</Link> 
              <span>/</span>
              <Link className="active" to="/library">Library</Link> 
          </div>
        </div> */}
        <div className={"bot-content-ct flex flex-wrap w-full secondary-color rounded-lg justify-start mt-4 sm:mt-2 pb-[200px] sm:pb-[40px] sm:ml-4 lg:ml-0 sm:pr-3 lg:pr-0"}>
          <h1 className='text-2xl font-bold px-0 sm:px-1  pb-2 text-primary-color flex items-center'>
          Library</h1>
          <p className='sub-heading text-left text-base sm:px-[6px] px-[30px] pt-3 pb-2 text-gray-400 '>Step into the future with our diverse range of AI Bots, specifically designed to support and enhance your personal and professional life. Here, you’ll discover bots for every occasion, each one ready to provide expert advice in areas from personal wellness and learning to business strategy and beyond.</p>
          {
            !isLoading ?
              data.map((item, i) =>
                <CategoryCard data={item} key={i} createChannel={createChannel} imagePath={SENDBIRD_INFO.studioUrl} />
              )
              :null
          }
        </div>
      </div>
      {/* <Mobfooter handleLoadMemberSelectionList={handleLoadMemberSelectionList} HandleHomeBtn={HandleHomeBtn} HandleLibBtn={HandleLibBtn} handleSearchBtn={handleSearchBtn} /> */}
    </div>
  )
}

export default Categorylist