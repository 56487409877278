import React,{useEffect, useState} from "react";
import { browserName } from 'react-device-detect';


const PopupShow= ({deviceType}) => {
    const [showPoptxt,setShowPopTxt] = useState(false);

    useEffect(()=>{
        if(localStorage.getItem("appPopup")=="true"){
            setShowPopTxt(false);
        }
        else if(deviceType === "IOS" && browserName === "Chrome"){
            setShowPopTxt(false);
        }
        else {
            setShowPopTxt(true);
        }

        
    },[]);

    const closeHandlerr = () =>{
        setShowPopTxt(false)
        localStorage.setItem("appPopup", "true");
    }

    return(
        <div className={`overlay flex demooo sm:hidden ${showPoptxt?'':'hidden'}`}>
            <div className="overlay-content relative fileupload-view rounded-md text-center">
                <span className="close-icon text-lg font-bold absolute right-1 top-1 rounded-full align-middle cursor-pointer text-tertiary-color" onClick={closeHandlerr}>✕</span>
                <h4 className="text-lg font-bold mb-2 text-tertiary-color">Add To Your Home Screen</h4>
                
                {
                    deviceType === "Android" ? 

                        <div>
                            <p>
                                Get message notifications and faster access by adding Brainstormer to your home screen. </p> 
                            <p>
                                1. Tap of <img className="install-icon" src="/assets/images/upload-android.png" alt="user-icon" /> icon
                            </p>   
                            <p>2. Then tap on "Add to Home Screen <img className="install-icon" src="/assets/images/add-to.png" alt="user-icon" />"</p>              
                        </div>
                    :
                    <>
                        {  deviceType === "IOS" && browserName === "Mobile Safari" ? 
                        <div className="modal-text">
                            <p>
                                Get message notifications and faster access by adding Brainstormer to your home screen. </p> 
                            <p>
                                1. Tap on <span className="icon-uploadIcon text-tertiary-color text-[32px]"></span> icon
                            </p>   
                            <p>2. Then tap on<b>  "Add To Home Screen <img className="install-icon" src="/assets/images/add-to.png" alt="user-icon" />"</b></p>              
                        </div> 
                            : 
                            null
                        }   
                    </>
                }
            </div>
        </div>
    )
}

export default PopupShow;