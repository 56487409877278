import { Link } from "react-router-dom";


const Breadcrumbs = ({botcategory}) =>{
    return(
        <div className="breadrumbs">
            <Link to="/">Home</Link> 
            <span>/</span>
            <Link to="/library">Library</Link> 
            <span>/</span>
            <span className="active" to="">{botcategory}</span> 

        </div>
    )
}

export default Breadcrumbs;