import React from "react";

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

const Highlight = ({ text = '', highlight = '' }) => {
    if (!highlight.trim()) {
        return <>{text}</>;
    }
    const regex = new RegExp(`(${escapeRegExp(highlight)})`, 'gi');
    const parts = text.split(regex);

    return (
        <>
            {parts.filter(part => part).map((part, i) =>
                regex.test(part) ? <mark className="bg-[#727272]" key={i}>{part}</mark> : <span key={i}>{part}</span>
            )}
        </>
    );
};

export default Highlight;