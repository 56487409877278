import moment from 'moment';

export const protectFromXSS = (text) => {
    return text
        .replace(/\&/g, '&amp;')
        .replace(/\</g, '&lt;')
        .replace(/\>/g, '&gt;')
        .replace(/\"/g, '&quot;')
        .replace(/\'/g, '&apos;');
};
// Old Function
// export const timestampToTime = (timestamp) => {
//     const now = new Date().getTime();
//     const nowDate = moment.unix(now.toString().length === 13 ? now / 1000 : now).format('MM/DD');
//     let date = moment.unix(timestamp.toString().length === 13 ? timestamp / 1000 : timestamp).format('MM/DD');
//     if (date === 'Invalid date') {
//         date = '';
//     }
//     return nowDate === date
//         ? moment.unix(timestamp.toString().length === 13 ? timestamp / 1000 : timestamp).format('HH:mm')
//         : date;
// };

export const timestampToTime = (timestamp) => {
    const now = new Date().setHours(0,0,0,0);
    const last = new Date(timestamp).setHours(0,0,0,0);
    let m =  moment.unix(last.toString().length === 13 ? last / 1000 : last)
    let m2 =  moment.unix(now.toString().length === 13 ? now / 1000 : now)

    const nowDate = moment.unix(now.toString().length === 13 ? now / 1000 : now).format('DD-MM');
    let date = moment.unix(last.toString().length === 13 ? last / 1000 : last).format('DD-MM');

    const nowDatebyweek = m2.diff(m, 'days');
    if (date === 'Invalid date') {
        date = '';
    }
    // const diffTime = Math.abs(m2.format('Y-m-d') - m.format('Y-m-d'));
    // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
   
    return nowDate === date
        ? "Today "+ moment.unix(timestamp.toString().length === 13 ? timestamp / 1000 : timestamp).format('HH:mm'):
        nowDatebyweek == 1?
        "Yesterday":
        (nowDatebyweek <= 7 && nowDatebyweek != 1) ?
        m.format('dddd')
        :  m.format('DD-MM-YY');
    
};

export const handleEnterPress = (event, callback) => {
    if (event.key === 'Enter' && event.target.value != '') {
        callback()
    }
}