import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import { useState, useEffect } from 'react';
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
} from "firebase/firestore";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Notiflix from 'notiflix';
import axios from "axios";
import { SENDBIRD_INFO } from '../constants/constants';
import Dropdown from './ui/Dropdown';
import { useTheme } from '../contexts/ThemeProvider';
import data from '../constants/themeDropdownData';

const Account = ({ setUserNickname }) => {

    const [user, loading, error] = useAuthState(auth);
    const [userData, setUserdata] = useState();
    const [isOpen, setIsOpen] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    const { theme, selectedTheme, toggleTheme } = useTheme();

    if (loading == true) {
        Notiflix.Loading.standard();
    }
    else {
        Notiflix.Loading.remove();
    }

    const fetchUserdata = async () => {
        const data = query(collection(db, "Users"), where('uid', '==', user.uid));
        getDocs(data).then((doc) => {
            setUserdata(doc?._snapshot?.docs?.keyedMap?.root?.value?.data?.value?.mapValue?.fields);
            setUserNickname(doc?._snapshot?.docs?.keyedMap?.root?.value?.data?.value?.mapValue?.fields.name?.stringValue);
        })
    }

    useEffect(() => {
        // console.log(user.uid);
        if (user) {
            fetchUserdata();
        }
    }, [location.pathname, user]);

    const deleteUserdata = async () => {
        Notiflix.Confirm.show(
            "Confirm",
            "Are you sure you would like to delete your account?",
            "Yes",
            "No",
            async () => {
                Notiflix.Loading.standard();
                const headers = {
                    'Content-Type': 'multipart/form-data',
                    'Api-token': SENDBIRD_INFO.apiToken,
                };
                if (user) {
                    const usertoken = await user.getIdToken();
                    headers.Authorization = `Bearer ${usertoken}`;
                }
                await axios({
                    url: `https://storm.brainstormer.io/delete_user`,
                    method: 'post',
                    headers: headers,
                })
                    .then((res) => res)
                    .then((response) => {
                        Notiflix.Notify.success('Account Deleted Successfully');
                        Notiflix.Loading.remove();
                        navigate('/login');
                    })
                    .catch((err) => {
                        Notiflix.Loading.remove();
                    });

            }
        )
    }

    useEffect(() => {
        if (!loading && !user) return navigate("/onboarding");
    }, [loading]);

    // const [isDarkTheme, setIsDarkTheme] = useState(localStorage.getItem('data-theme') == 'dark');

    // useEffect(() => {
    //     if (!('theme-set-user' in localStorage)) {
    //         setIsDarkTheme(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)
    //     } else {
    //         setIsDarkTheme(localStorage.getItem('data-theme') == 'dark')
    //     }
    // }, [])

    // function processThemeChange() {
    //     localStorage.setItem('theme-set-user', 'true');
    //     setIsDarkTheme(isDarkTheme => !isDarkTheme);
    //     if (localStorage.getItem('data-theme')) {
    //         if (localStorage.getItem('data-theme') === 'light') {
    //             // document.documentElement.classList.add('dark');
    //             document.querySelector('html').setAttribute('data-theme', 'dark');
    //             localStorage.setItem('data-theme', 'dark');
    //         } else {
    //             // document.documentElement.classList.remove('dark');
    //             document.querySelector('html').removeAttribute('data-theme', 'dark');
    //             document.querySelector('html').setAttribute('data-theme', 'light');
    //             localStorage.setItem('data-theme', 'light');
    //         }
    //     } else {
    //         if (document.documentElement.classList.contains('dark')) {
    //             document.querySelector('html').removeAttribute('data-theme', 'dark');
    //             document.querySelector('html').setAttribute('data-theme', 'light');
    //             // document.documentElement.classList.remove('dark');
    //             localStorage.setItem('data-theme', 'light');
    //         } else {
    //             // document.documentElement.classList.add('dark');
    //             document.querySelector('html').removeAttribute('data-theme', 'light');
    //             document.querySelector('html').setAttribute('data-theme', 'dark');
    //             localStorage.setItem('data-theme', 'dark');
    //         }
    //     }
    // }

    // function switchTheme() {
    //     processThemeChange();
    // }

    // useEffect(()=>{
    //     console.log(isDarkTheme);
    // },[]);

    const handleSelect = (value) => {
        toggleTheme(value.value);
    };

    return (
        <div className='w-[100%] h-fit lg:w-[100%] bg-gray-color sm:bg-secondary-color pb-[100px] sm:pb-[0px]'>
            <Helmet>
                <title>Brainstormer User Account</title>
            </Helmet>
            {user ?
                <>
                <div className="myacount-wraaper">
                    <div className="myaccount-inner">
                        <div className="account-header bg-gray-color  px-3 lg:px-[30px] pb-[20px]">
                            <div className="breadcrumbs-ct hidden sm:block py-2">
                                <ul className='flex items-center'>
                                    <li className='text-[14px] list-none text-graydark-color mr-[7px] font-semibold cursor-pointer'><Link to={'/'}>Home</Link></li><span>/</span>
                                    <li className='text-[14px] list-none text-tertiary-color mx-[7px] font-semibold'>My Profile</li>
                                </ul>
                            </div>
                            <div className="mob-heading flex justify-start items-center my-[20px] sm:my-[14px] sm:hidden">
                                {/* <Link className="ml-[-10px]" to="/"><img src="/assets/images/leftArrow.svg" alt="left-arrow-icon" /></Link> */}
                                <h2 className='font-bold text-[20px] text-tertiary-color'>Account</h2>
                            </div>
                            <div className='bot-name-wrapper flex justify-start xl:justify-between items:start xl:items-center mt-[10px] sm:my-[15px] w-[100%] flex-col xl:flex-row xl:pr-[30px]'>
                                <div className='w-[80%] flex justify-start items-center mt-[10px] sm:my-[15px]'>
                                    <div className="img-ct w-[65px] h-[65px] sm:w-[65px] sm:h-[65px] overflow-hidden rounded-[50%]">
                                        <img src={userData?.profileImage?.stringValue ? " " + userData?.profileImage?.stringValue : '/assets/images/app_dark_img.png'} alt="bot-img" className='w-full h-full object-cover' />
                                    </div>
                                    <div className='account-name-ct ml-3 flex justify-between items-center max-w-[75%]'>
                                        <div className='mr-2 sm:mr-3 max-w-fit'>
                                            <h1 className='text-tertiary-color text-[20px] sm:text-[20px] font-bold leading-[24px] sm:leading-[24px]'>{userData && " " + userData.name?.stringValue}</h1>
                                            <span className='text-[14px] sm:text-[16px] text-graydark-color font-semibold'>by Brainstormer.io</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='block md:hidden mt-8'>
                                    <Dropdown
                                        id='theme'
                                        isOpen={isOpen}
                                        setIsOpen={setIsOpen}
                                        title='Select Theme'
                                        data={data}
                                        hasImage={true}
                                        imageType={'svg'}
                                        dropdownContainerStyle={`bg-secondary-color rounded-[8px] border border-solid border-[#e5e5e5] py-4 px-5 relative z-20`}
                                        dropdownTitleStyle='text-tertiary-color text-[16px] font-semibold ml-4'
                                        dropdownIconStyle='fill-tertiary-color'
                                        dropdownMenuStyle='rounded-[8px] bg-grey-highlight border border-solid border-[#e5e5e5] z-20'
                                        dropdownMenuSelectedItemStyle='bg-secondary-color'
                                        selectedId={data.filter(item => item.value === selectedTheme)[0].id}
                                        onSelect={handleSelect}
                                    />
                                </div>
                                {/* <div className='hidden md:flex align-items-center justify-content-center mr-[4px] relative left-[78px] top-[15px] xl:left-auto xl:top-auto mb-[15px] xl:mb-[0px]'>
                            <span className='text-tertiary-color inline-block mr-[10px] font-semibold'>Switch Mode</span>
                            <label className="relative inline-flex items-center cursor-pointer">
                                <input type="checkbox" className="sr-only peer all-checkbox" checked={isDarkTheme} onChange={switchTheme} value="" />
                                <div className="notification-radio-icon w-[53px] h-7 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[3px] after:secondary-color after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-brand-color">
                                    <div className={`${isDarkTheme ? '' : 'hidden'} light-icon-ct absolute left-[6px] top-[6px]`}>
                                        <svg id="theme-toggle-light-icon"
                                            className={`${isDarkTheme ? '' : 'hidden'} w-4 h-4`} fill="#ffc409"
                                            viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                                                fillRule="evenodd" clipRule="evenodd">
                                            </path>
                                        </svg>
                                    </div>
                                    <div className={`${isDarkTheme ? 'hidden' : ''} dark-icon-ct absolute right-[6px] top-[6px]`}>
                                        <svg id="theme-toggle-dark-icon"
                                            className={`${isDarkTheme ? 'hidden' : ''} w-4 h-4`} fill="currentColor"
                                            viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
                                        </svg>
                                    </div>
                                </div>
                            </label>
                        </div> */}
                            </div>
                        </div>
                        <div className="account-links-wrapper px-3 lg:px-0 lg:mx-[30px] w-full lg:w-[92%] flex justify-between flex-col lg:flex-row">
                            <div className="general-settings-ct w-full lg:w-[48.5%]">
                                <h2 className='text-tertiary-color text-[17px] font-bold mt-[20px] mb-[15px] px-[4px] sm:px-0'>General Settings</h2>
                                <ul className='links px-[15px] list-none divide-y divide-[#E5E5E5] border rounded-[8px] bg-secondary-color mb-[10px]'>
                                    {/* <li className=''>
                                <Link to="/account/dashboard" className="px-[10px] py-[20px] flex justify-between items-center">
                                    <span className='flex justify-start items-center'>
                                        <img src="/assets/images/dashboard-icon.svg" alt="dashboard icon" />
                                         <span className='text-tertiary-color text-[16px] font-semibold ml-[18px] hover:text-opacity-75'>Dashboard</span>
                                    </span> 
                                    <img src='/assets/images/right-arrow-black.svg'></img>
                                </Link>
                            </li> */}
                                    <li className='border-1 border-b-[#E5E5E5]'>
                                        <Link to="/account/notification-settings" className="px-[10px] py-[20px] flex justify-between items-center">
                                            <span className='flex justify-start items-center'>
                                                <span className='icon-bellIcon text-[32px] ml-[-5px] text-primary-color'></span>
                                                <span className='text-tertiary-color text-[16px] font-semibold ml-[18px] hover:text-opacity-75'>Notification</span>
                                            </span>
                                            <img src='/assets/images/right-arrow-black.svg'></img>
                                        </Link>
                                    </li>
                                    {/* <li className='border-1 border-b-[#E5E5E5]'>
                                <Link to="/account/subscription" className="px-[10px] py-[20px] flex justify-between items-center">
                                    <span className='flex justify-start items-center'>
                                        <img src="/assets/images/subscription-icon.svg" alt="feedback icon" />
                                         <span className='text-tertiary-color text-[16px] font-semibold ml-[18px] hover:text-opacity-75'>Subscription</span>
                                    </span> 
                                    <img src='/assets/images/right-arrow-black.svg'></img>
                                </Link>
                            </li> */}
                                </ul>
                            </div>
                            <div className="support-settings-ct  w-full lg:w-[48.5%]">
                                <h2 className='text-tertiary-color text-[17px] font-bold mt-[20px] mb-[15px] hover:text-opacity-75 px-[4px] sm:px-0 hidden lg:block'>&nbsp; </h2>
                                <ul className='links px-[15px] list-none divide-y divide-[#E5E5E5] border rounded-[8px] bg-secondary-color'>
                                    <li className='border-1 border-b-[#E5E5E5]'>
                                        <Link to="/account/account-settings" className="px-[10px] py-[20px] flex justify-between items-center">
                                            <span className='flex justify-start items-center'>
                                                <span className='icon-settingIcon text-primary-color text-[30px] ml-[-5px]'></span>
                                                <span className='text-tertiary-color text-[16px] font-semibold ml-[18px] hover:text-opacity-75'>Account Settings</span>
                                            </span>
                                            <img src='/assets/images/right-arrow-black.svg'></img>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="creater-wrapper px-3 lg:px-0 lg:mx-[30px] w-full lg:w-[92%] mt-[20px] mb-[40px] sm:mb-[80px]">
                            <div className="creator-ct flex justify-between items-center my-[30px] px-[10px] sm:px-[24px] py-[18px] sm:py-[25px] bg-[#E6E6E6] rounded-[3px]">
                                <div className="content-ct flex justify-between items-center">
                                    <img src="/assets/images/creator_img.svg" className="w-[26px] lg:w-[42px]" alt="creator img" />
                                    <h2 className='text-[24px] text-tertiary-color font-bold mx-[10px] hidden lg:block'>Are you a creator?</h2>
                                    <h2 className='text-[16px] text-tertiary-color font-bold mx-[10px] block lg:hidden'>Create unlimited bots</h2>
                                    <p className='text-tertiary-color m-0 mt-[8px] font-semibold text-[15px] hidden lg:block'>Join our creative program to create infinite bots</p>
                                </div>
                                <div className="button-ct">
                                    <button className='text-secondary-color bg-primary-color h-[35px] flex justify-center items-center hover:bg-opacity-75 w-[150px] text-[16px] px-[0px] rounded-[6px] hidden sm:flex'>Become creator</button>
                                    <button className='text-secondary-color bg-primary-color h-[35px] flex justify-center items-center hover:bg-opacity-75 w-[100px] text-[16px] px-[0px] rounded-[6px] sm:hidden'>Join Studio</button>
                                </div>
                            </div>
                        </div> */}
                        <div className="bottom-sec px-3 lg:px-0 lg:mx-[30px] w-full lg:w-[92%] mt-[50px]">
                            {/* <a href="" className='text-gray-600 font-semibold w-full text-[13px] block text-center hover:text-gray-500'>Terms of service & Privacy policy</a> */}
                            <a href="/logout" className='w-full text-notice-color text-[18px] h-[45px] flex justify-center items-center rounded-[6px] font-bold my-[20px] bg-secondary-color outline outline-offset-2 outline-1 outline-notice-color'>Logout</a>
                            <a className='text-tertiary-color font-semibold w-full text-[16px] block text-center hover:text-gray-600 underline mb-[30px] cursor-pointer' onClick={deleteUserdata}>Delete Account</a>
                        </div>
                    </div>
                </div>

                {/* Dropdown overlay */}
                <div className={`absolute top-0 left-0 w-full h-full bg-black/40 dark:bg-black/60 ${isOpen ? 'opacity-100 z-10 translate-y-0 max-h-[500px]' : 'opacity-0 z-10 translate-y-3 max-h-[0px] overflow-hidden'}`} />
                
                </>
                : !loading ?
                    <p className='m-[15px] sm:m-[25px] text-graydark-color font-semibold'>You are not logged In! Please <Link to="/login" className='text-primary-color font-semibold underline'>Login</Link> and try again</p>
                    : null}
        </div>
    )
}

export default Account