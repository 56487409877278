import { useEffect } from 'react';

const useOutsideClick = ({ ref, handler, dependencies = [] }) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            const isOutsideClick = [ref, ...dependencies].every(
                (dependencyRef) => dependencyRef.current && !dependencyRef.current.contains(event.target)
            );

            if (isOutsideClick) {
                handler();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, handler, ...dependencies]);
};

export default useOutsideClick;