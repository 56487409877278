const data = [
    {
        "id": "1",
        "name": "Light",
        "value": 'light',
        "image":
            `<svg fill="currentColor" width='15' height='15' viewBox='0 0 15 15' xmlns='http://www.w3.org/2000/svg'>
                <path d="M7.5 2.75C4.88084 2.75 2.75 4.88084 2.75 7.5C2.75 10.1192 4.88084 12.25 7.5 12.25C10.1192 12.25 12.25 10.1192 12.25 7.5C12.25 4.88084 10.1192 2.75 7.5 2.75ZM7.5 11.25C6.75832 11.25 6.0333 11.0301 5.41661 10.618C4.79993 10.206 4.31928 9.62028 4.03545 8.93506C3.75162 8.24984 3.67736 7.49584 3.82206 6.76841C3.96675 6.04098 4.3239 5.3728 4.84835 4.84835C5.3728 4.3239 6.04098 3.96675 6.76841 3.82206C7.49584 3.67736 8.24984 3.75162 8.93506 4.03545C9.62028 4.31928 10.206 4.79993 10.618 5.41661C11.0301 6.0333 11.25 6.75832 11.25 7.5C11.2489 8.49422 10.8534 9.44739 10.1504 10.1504C9.44739 10.8534 8.49422 11.2489 7.5 11.25Z" />
                <path d="M8 0H7V1.5H8V0Z" />
                <path d="M8 13.5H7V15H8V13.5Z" />
                <path d="M15 7H13.5V8H15V7Z" />
                <path d="M1.5 7H0V8H1.5V7Z" />
                <path d="M12.3536 11.6464L11.6465 12.3535L12.6465 13.3535L13.3536 12.6464L12.3536 11.6464Z" />
                <path d="M2.35361 1.64639L1.64648 2.35352L2.64649 3.35352L3.35362 2.64639L2.35361 1.64639Z" />
                <path d="M2.64647 11.6465L1.64648 12.6465L2.3536 13.3536L3.35359 12.3536L2.64647 11.6465Z" />
                <path d="M12.6465 1.64648L11.6465 2.64648L12.3536 3.35361L13.3536 2.35361L12.6465 1.64648Z" />
            </svg>`
    },
    {
        "id": "2",
        "name": "Dark",
        "value": "dark",
        "image":
            `<svg width="15" height="15" fill="currentColor" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.88388 15C5.77215 15 3.79074 14.1815 2.30458 12.6954C0.818424 11.2093 1.84639e-07 9.22779 1.84639e-07 7.11602C-0.000315061 5.65924 0.403053 4.23086 1.16533 2.98943C1.92761 1.748 3.01898 0.742074 4.31831 0.083312C4.45199 0.0151687 4.60277 -0.0120919 4.75185 0.00493331C4.90092 0.0219585 5.04168 0.0825153 5.15655 0.179044C5.27142 0.275573 5.35531 0.403798 5.39775 0.547712C5.4402 0.691626 5.43931 0.844854 5.39521 0.988268C5.02444 2.18586 4.988 3.462 5.28982 4.6788C5.59165 5.8956 6.22027 7.00677 7.10774 7.89226C7.74462 8.53387 8.50245 9.04279 9.33734 9.38955C10.1722 9.73631 11.0676 9.91402 11.9717 9.91239C12.6632 9.91292 13.351 9.80919 14.0116 9.6047C14.155 9.56057 14.3083 9.55966 14.4522 9.6021C14.5961 9.64453 14.7244 9.72843 14.8209 9.84331C14.9175 9.95819 14.978 10.099 14.995 10.2481C15.0121 10.3972 14.9848 10.5479 14.9166 10.6816C14.2578 11.981 13.2519 13.0723 12.0105 13.8346C10.7691 14.5969 9.34067 15.0003 7.88388 15ZM4.29103 1.24287C3.28347 1.85591 2.45117 2.71859 1.87463 3.74748C1.2981 4.77636 0.996849 5.93662 1.00002 7.11602C1.00002 10.9118 4.08809 14 7.88388 14C9.06329 14.0032 10.2236 13.7019 11.2524 13.1254C12.2813 12.5488 13.144 11.7165 13.7571 10.709C13.1716 10.8445 12.5726 10.9128 11.9717 10.9125C10.9363 10.9145 9.91079 10.7111 8.95453 10.314C7.99827 9.91699 7.13025 9.33422 6.40073 8.59946C5.45301 7.65353 4.76197 6.4817 4.39284 5.19457C4.02371 3.90745 3.98867 2.54748 4.29103 1.24306V1.24287Z" />
            </svg>`
    },
    {
        "id": "3",
        "name": "Device Default",
        "value": "device",
        "image":
            `<svg width="16" height="12" fill="currentColor" viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.1336 9.6C14.3457 9.59975 14.549 9.51539 14.699 9.36541C14.8489 9.21544 14.9333 9.0121 14.9336 8.8V0.8C14.9333 0.587903 14.8489 0.384563 14.699 0.234587C14.549 0.0846118 14.3457 0.000247015 14.1336 0H1.86689C1.6548 0.000247015 1.45146 0.0846118 1.30148 0.234587C1.15151 0.384563 1.06714 0.587903 1.06689 0.8V8.8C1.06714 9.0121 1.15151 9.21544 1.30148 9.36541C1.45146 9.51539 1.6548 9.59975 1.86689 9.6H14.1336ZM2.13356 1.06667H13.8669V8.53333H2.13356V1.06667Z" />
                <path d="M16 10.6665H0V11.7332H16V10.6665Z" />
            </svg>`
    }
]

export default data;