import { useEffect, useRef, useState } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";

const Dropdown = ({
    id,
    isOpen,
    setIsOpen,
    title = 'Select',
    data,
    position = 'bottom',
    hasImage,
    imageType,
    dropdownContainerStyle,
    dropdownTitleStyle,
    dropdownIconStyle,
    dropdownMenuStyle,
    dropdownMenuItemStyle,
    dropdownMenuSelectedItemStyle,
    selectedId,
    onSelect
}) => {
    
    const [selectedItem, setSelectedItem] = useState(
        selectedId ? data?.find((item) => item.id === selectedId) : undefined
    );

    const handleChange = (item) => {
        setSelectedItem(item);
        onSelect && onSelect(item);
        setIsOpen(false);
    };

    useEffect(() => {
        if (selectedId && data) {
            const newSelectedItem = data.find((item) => item.id === selectedId);
            newSelectedItem && setSelectedItem(newSelectedItem);
        } else {
            setSelectedItem(undefined);
        }
    }, [selectedId, data]);

    const dropdownRef = useRef(null);

    useOutsideClick({
        ref: dropdownRef,
        handler: () => setIsOpen(false),
    });

    return (
        <div ref={dropdownRef} className='relative'>
            <button
                id={id}
                aria-label='Toggle dropdown'
                aria-haspopup='true'
                aria-expanded={isOpen}
                type='button'
                onClick={() => setIsOpen(!isOpen)}
                className={`flex justify-between items-center gap-5 rounded w-full py-2 px-4 bg-blue-500 text-white ${dropdownContainerStyle} `}
            >
                <div className="flex items-center">
                    {hasImage && (

                        imageType === 'svg' ? 
                            <div className="text-primary-color dropdown-svg-container" dangerouslySetInnerHTML={{ __html: selectedItem?.image }} />
                        :
                            <img
                            src={selectedItem?.image}
                            alt='image'
                            className='w-4 h-4 rounded-full bg-gray-400 object-cover me-2'
                        />

                    )}
                    <span className={dropdownTitleStyle}>{selectedItem?.name || title}</span>
                </div>
                <div className={`transition-all duration-300 ease-in ml-2 ${!isOpen ? 'rotate-180' : ''}`}>
                    <svg className={`${dropdownIconStyle}`} width="11" height="6" viewBox="0 0 11 6" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.76708 0.135713L0.120833 5.01209C0.0432693 5.09345 0 5.20155 0 5.31396C0 5.42637 0.0432693 5.53447 0.120833 5.61584L0.126084 5.62109C0.163685 5.66067 0.208946 5.69219 0.259112 5.71372C0.309278 5.73526 0.363302 5.74637 0.417896 5.74637C0.47249 5.74637 0.526513 5.73526 0.57668 5.71372C0.626846 5.69219 0.672107 5.66067 0.709708 5.62109L5.08471 1.02909L9.45796 5.62109C9.49556 5.66067 9.54082 5.69219 9.59099 5.71372C9.64115 5.73526 9.69518 5.74637 9.74977 5.74637C9.80437 5.74637 9.85839 5.73526 9.90856 5.71372C9.95872 5.69219 10.004 5.66067 10.0416 5.62109L10.0468 5.61584C10.1244 5.53447 10.1677 5.42637 10.1677 5.31396C10.1677 5.20155 10.1244 5.09345 10.0468 5.01209L5.40058 0.135713C5.35972 0.092827 5.31058 0.0586853 5.25613 0.0353571C5.20169 0.012029 5.14307 0 5.08383 0C5.0246 0 4.96598 0.012029 4.91153 0.0353571C4.85709 0.0586853 4.80794 0.092827 4.76708 0.135713Z" />
                    </svg>
                </div>
            </button>

            {isOpen && (
                <div aria-label='Dropdown menu' className={`absolute w-full max-h-52 overflow-y-auto rounded shadow-sm z-10 ${position === 'bottom' ? 'top-full mt-2' : position === 'top' ? 'bottom-full mb-2' : ''} ${dropdownMenuStyle}`}>
                    <ul
                        role='menu'
                        aria-labelledby={id}
                        aria-orientation='vertical'
                        className='mt-0'
                    >
                        {data?.map((item) => (
                            <li
                                key={item.id}
                                onClick={() => handleChange(item)}
                                className={`${dropdownMenuItemStyle} flex items-center cursor-pointer hover:bg-gray-200 py-3.5 px-5 ${selectedItem?.id === item.id ? `bg-gray-300 ${dropdownMenuSelectedItemStyle}` : ''}`}>
                                {hasImage && (

                                    imageType === 'svg' ? 
                                        <div className="text-primary-color dropdown-svg-container" dangerouslySetInnerHTML={{ __html: item?.image }} />
                                    :
                                        <img
                                        src={item.image}
                                        alt='image'
                                        className='w-5 h-5 rounded-full bg-gray-400 object-cover me-2'
                                    />
                                
                                )}
                                <span className="ml-4 font-semibold text-tertiary-color">{item.name}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default Dropdown;